<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="ml-6 mt-6">
          <h3 v-if="supplierName" class="supplier-health-heading">Supplier Health - {{ supplierName }}</h3>
        </div>
        <!-- <div class="vx-row">
          <div class="vx-col w-full">
            <vs-input class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          </div>
        </div> -->

        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="healthData"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="false"
          @selection-changed="onSelectionChanged"
        >
        </ag-grid-vue>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import Vue from "vue"
import { formatISO } from 'date-fns'


export default {
  components: {
    AgGridVue,
    
  },
  props: { 
    supplierId: { required: true }
  },
  data () {
    return {
      healthData: [],
      supplierName: 'XYZ Supplier',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      searchQuery: '',
      cellRendererComponents: {}
    }
  },
  computed: {
  },
  created () {
    this.$vs.loading();
      this.$http.get(`api/admin/suppliers/${this.supplierId}/health`)
      .then(response => {
        this.supplierName = response.data.supplierName
        this.healthData = response.data.healthData
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Booking Stage', field: 'bookStage', width: 200,  suppressSizeToFit: true },
      { headerName: 'Total Requests', field: 'totalRequests', width: 160,  suppressSizeToFit: true },
      { headerName: 'Total OK', field: 'totalOk', width: 160,  suppressSizeToFit: true },
      { headerName: 'Total Errors', field: 'totalErrors', width: 160,  suppressSizeToFit: true },
      { headerName: 'Mapping Exception', field: 'mappingExceptions', width: 160,  suppressSizeToFit: true },
      { headerName: 'Serious Errors', field: 'seriousErrors', width: 160,  suppressSizeToFit: true },
      { headerName: 'Avg. Send/Receive (sec)', field: 'avgSendReceiveSec', width: 160,  suppressSizeToFit: true },
      { headerName: 'Avg. Processing (sec)', field: 'avgProcessingSec', width: 160,  suppressSizeToFit: true },
      { headerName: 'Avg. Total (sec)', field: 'avgTotalSec', width: 160,  suppressSizeToFit: true },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    onAdd () {
      this.$router.push({ name: '', params: {}})
    },

    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onGridReady(params) {

    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    }
  }
}
</script>

<style>
.supplier-health-heading {
  color: #636363
}
</style>